import { graphql } from "gatsby"
import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout/t1/layout"
import FooterSection from "../components/use-cases/use-cases-detail/footer-section";


const UseCasesDetailPage = ({ data }) => {
  return (
    <Layout>
   <MDXRenderer>{data.mdx.body}</MDXRenderer>
    <FooterSection/>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        subTitle
        cardImage
      }
      body
    }
  }
`

export default UseCasesDetailPage
