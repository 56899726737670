import React from "react"
import { Link } from "gatsby"
import "./index.scss"
const PurpleButton = ({ to="/get-started/",data, size }) => {
  return (
    <div id="PurpleButton" className="PurpleButton" data-testid="PurpleButton">
      <Link to={to}>
      <button
        className="purple-btn"
        style={{ width: `${size}` }}
        data-testid="buttonSize"
      >
        {data}
      </button>
      </Link>
    </div>
  )
}

export default PurpleButton
