import React from "react"
import PurpleButton from "../../../home/buttons/purple-button"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"
const FooterSection = () => {
  const result = useStaticQuery(graphql`
    {
      mdx(fileAbsolutePath: { regex: "/(uses-case-footer)/" }) {
        frontmatter {
          titleSection1
          titleSection2
          titleSection3
          titleButton1
          titleButton2
        }
      }
    }
  `)

  const data = result.mdx.frontmatter
  return (
    <div id="FooterSection" className=" sect FooterSection  row  ">
      <div className=" bcontainer col-lg-6 col-12">
        <p className="h4 titleFooter text-center pt-3">{data?.titleSection1}</p>
        <div className="row justify-content-center py-4">
          <div className="secondBtn text-center py-2">
            <PurpleButton to="/get-started/" data={data?.titleButton1} />
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <p className="h4 titleFooter text-center pt-3">{data?.titleSection2}</p>
        <div className="row justify-content-center  py-4">
          <div className="secondBtn  text-center py-2">
            <PurpleButton to="/contact/" data={data?.titleButton2} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterSection
